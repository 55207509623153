// src/context/AuthContext.jsx
import React, { createContext, useState, useEffect } from "react";

export const AuthContext = createContext(); // Asegúrate de exportar el contexto

const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(sessionStorage.getItem("token") || null);  
  const [isAuthenticated, setIsAuthenticated] = useState(!!token);
  const isAdminUser = sessionStorage.getItem("isAdmin") === 'true' ? true : false;

  useEffect(() => {
    if (token) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [token]);

  const login = (newToken) => {
    const id_place = newToken.places[0] ? newToken.places[0].id : null
    const name_place = newToken.places[0] ? newToken.places[0].name_place : null
    setToken(newToken.access_token);
    setIsAuthenticated(true);
    sessionStorage.setItem("token", newToken.access_token);
    sessionStorage.setItem("isAdmin", newToken.isAdmin);
    if (id_place && name_place) {
      sessionStorage.setItem("selectedPlaceId", id_place);
      sessionStorage.setItem("selectedPlaceName", name_place);
    }
  };

  const logout = () => {
    sessionStorage.clear();
    setToken(null);
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider
      value={{ token, isAuthenticated, login, logout, isAdminUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
