import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import EventRegistration from "@components/EventCamera/EventRegistration";
import { createUser, createAdminUser } from "@services/userService"; // Servicios para crear usuario y administrador
import SuccessMessageModal from "@components/Reusable/SuccessMessageModal";
import WarningMessageModal from "@components/Reusable/WarningMessageModal";
import { assignPlaceToUser } from "../../services/userPlaceService";

const CreateUserModal = ({ isOpen, onClose }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [userData, setUserData] = useState({
    username: "",
    password: "",
    email: "",
    first_name: "",
    second_name: "",
    first_last_name: "",
    second_last_name: "",
    documento: "",
    photo: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [successMessageVisible, setSuccessMessageVisible] = useState(false);
  const [warningMessageVisible, setWarningMessageVisible] = useState(false);
  const [selectedPlaceId, setSelectedPlaceId] = useState(parseInt(sessionStorage.getItem("selectedPlaceId"), 10) || null);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleCheckboxChange = () => {
    setIsAdmin(!isAdmin);
    // Resetear datos adicionales si cambia de admin a usuario normal
    if (isAdmin) {
      setUserData({
        username: userData.username,
        password: userData.password,
        email: "",
        first_name: "",
        second_name: "",
        first_last_name: "",
        second_last_name: "",
        documento: "",
        photo: "",
      });
    }
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const toggleShowPassword = () => setShowPassword((prev) => !prev);
  const toggleShowConfirmPassword = () =>
    setShowConfirmPassword((prev) => !prev);

  const passwordIsValid = () =>
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(userData.password);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePhotoCapture = (photo) => {
    setUserData((prev) => ({
      ...prev,
      photo: photo.photo,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    let response;

    if (!userData.username || !userData.password) {
      setErrorMessage("El nombre de usuario y la contraseña son obligatorios.");
      setWarningMessageVisible(true);
      return;
    }

    if (!userData.password || !confirmPassword) {
      setErrorMessage("Todos los campos de contraseña son obligatorios.");
      setWarningMessageVisible(true);
      return;
    }

    if (userData.password !== confirmPassword) {
      setErrorMessage("Las contraseñas no coinciden.");
      setWarningMessageVisible(true);
      return;
    }

    if (!passwordIsValid()) {
      setErrorMessage(
        "La contraseña debe tener al menos 8 caracteres, una mayúscula, una minúscula y un número."
      );
      setWarningMessageVisible(true);
      return;
    }

    if (
      isAdmin &&
      (!userData.email ||
        !userData.first_name ||
        !userData.first_last_name ||
        !userData.documento ||
        !userData.photo)
    ) {
      setErrorMessage(
        "Todos los campos de administrador son obligatorios, incluyendo la foto."
      );
      setWarningMessageVisible(true);
      return;
    }

    setIsLoading(true);
    setErrorMessage("");

    try {
      if (isAdmin) {
        response = await createAdminUser(userData);
      } else {
        const { username, password } = userData;
        response = await createUser({ username, password });
      };
      if (selectedPlaceId) {
        await assignPlaceToUser({ user_id: response.data.user.id, place_id: selectedPlaceId });
      }
      setIsLoading(false);
      setSuccessMessageVisible(true);
      setUserData({
        username: "",
        password: "",
        email: "",
        first_name: "",
        first_last_name: "",
        second_last_name: "",
        documento: "",
        photo: "",
      });
      setIsAdmin(false);
    } catch (error) {
      setErrorMessage("Error al registrar el usuario.");
      setWarningMessageVisible(true);
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Crear Nuevo Usuario</DialogTitle>
      <DialogContent dividers>
        {isLoading ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <Box component="form">
            <FormControlLabel
              control={
                <Checkbox
                  checked={isAdmin}
                  onChange={handleCheckboxChange}
                  color="primary"
                />
              }
              label="Crear Usuario Administrador"
            />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  label="Nombre de Usuario *"
                  name="username"
                  value={userData.username}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Nueva Contraseña *"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  value={userData.password}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={toggleShowPassword}>
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Confirmar Contraseña *"
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={toggleShowConfirmPassword}>
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              {/* Campos adicionales para usuario administrador */}
              {isAdmin && (
                <>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Primer Nombre *"
                      name="first_name"
                      value={userData.first_name}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Segundo Nombre"
                      name="second_name"
                      value={userData.second_name}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Primer Apellido *"
                      name="first_last_name"
                      value={userData.first_last_name}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Segundo Apellido"
                      name="second_last_name"
                      value={userData.second_last_name}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Documento de Identificación *"
                      name="documento"
                      value={userData.documento}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Correo Electrónico *"
                      name="email"
                      value={userData.email}
                      onChange={handleChange}
                      type="email"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">Tomar foto *</Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        width: "100%",
                        padding: 2,
                      }}
                    >
                      <EventRegistration
                        data={userData}
                        onPhotoTaken={handlePhotoCapture}
                      />
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} style={{ color: "#1d1d1b" }}>
          Cancelar
        </Button>
        <Button
          onClick={handleSave}
          style={{ backgroundColor: "#e42316", color: "#f1f1f1" }}
          disabled={isLoading}
          variant="contained"
        >
          Guardar
        </Button>
      </DialogActions>

      <SuccessMessageModal
        message="Usuario registrado exitosamente."
        isOpen={successMessageVisible}
        onClose={() => window.location.reload()}
      />
      <WarningMessageModal
        message={errorMessage}
        isOpen={warningMessageVisible}
        onClose={() => setWarningMessageVisible(false)}
      />
    </Dialog>
  );
};

export default CreateUserModal;
