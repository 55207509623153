import React from "react";
import "@styles/MessageModal.css";

const WarningMessageModal = ({ message, isOpen, onClose }) => {

  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-container warning">
        <div className="modal-header">
          <h2>¡Error!</h2>
          <button className="close-button" onClick={onClose}>
            ✖
          </button>
        </div>
        <div className="modal-body">
          <p>{message}</p>
        </div>
      </div>
    </div>
  );
};

export default WarningMessageModal;

