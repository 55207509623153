import React, { useEffect } from "react";
import "@styles/MessageModal.css";

const SuccessMessageModal = ({ message, isOpen, onClose }) => {

  const handleClose = () => {
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-container success">
        <div className="modal-header">
          <h2>¡Éxito!</h2>
          <div className="icon success-icon">✔</div>
        </div>
        <div className="modal-body">
          <p>{message}</p>
        </div>
        <div className="modal-footer">
          <button className="btnCloseModal" onClick={handleClose}>
            Cerrar
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessMessageModal;

