// Reutilizable: Función para convertir una imagen capturada en Base64
export const captureImageToBase64 = (videoElement) => {
  return new Promise((resolve, reject) => {
    try {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;
      context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

      // Convertir la imagen en base64
      const base64Image = canvas.toDataURL("image/jpeg");
      resolve(base64Image);
    } catch (error) {
      reject(error);
    }
  });
};

// Reutilizable: Función para convertir una imagen base64 en un src para un elemento img
export const base64ToImageSrc = (base64String) => {
  return `data:image/jpeg;base64,${base64String}`;
};
