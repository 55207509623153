import api from "@utils/api";

export const getEmployeesRegisters = () => api.get("/employee_register/");

export const getEmployeeById = (id) => api.get(`/employee_register/${id}`);
export const getEmployeesRegistersByPlaceId = (id) =>
  api.get(`/employee_register/place/${id}`);
export const createEmployeeRegister = (employeeRegister) =>
  api.post("/employee_register", employeeRegister);

export const updateEmployee = (id, employee) => api.put(`/employee_register/${id}`, employee);

export const deleteEmployeeRegister = (id) => api.delete(`/employee_register/${id}`);